<template>
  <div>
    <div class="outside">
      <div class="potential">
        <rxNavBar title="租约变更" v-if="fromType == '0'"></rxNavBar>
        <rxNavBar title="租约变更" androidOrIOSFlag="true" v-else></rxNavBar>
      </div>
      <!-- 顶部固定区域-->
      <div class="header">
        <!-- 合同切换区域-->
        <div class="choose">
          <div class="choose-one">
            <div :class="type=='1'?'choose-one-textActive':'choose-one-text'" @click="showTenant">租客合同</div>
            <div class="choose-one-icon" v-show="type=='1'"></div>
          </div>
          <div class="choose-line"></div>
          <div class="choose-one">
            <div :class="type!='1'?'choose-one-textActive':'choose-one-text'" @click="showOwner">业主合同</div>
            <div class="choose-one-icon" v-show="type!='1'"></div>
          </div>
        </div>
        <!-- 搜索区域和类型区域-->
        <div class="searchAndType">
          <!-- 搜索区域-->
          <div class="search">
            <div class="search-icon"></div>
            <input type="text" placeholder="合同编号/房源地址/姓名、手机号" v-model="searchName" @keyup.enter="searchSubmit">
          </div>
          <!-- 类型区域-->
          <div class="typeAndChange">
            <div class="types">
              <span class="type" v-if="typeList[number]" @click="isShow = !isShow" id="downList">{{typeList[number].dictionaryTitle}}</span>
              <div :class="isShow?'small-icon-rotate':'small-icon'"></div>
            </div>
            <div class="switchIdentity" @click="switchIdentity">
              切换为{{staffType?'房源负责人':'合同维护人'}}
            </div>
          </div>
<!--          下拉列表-->
          <van-popup v-model="isShow" position="bottom">
            <van-picker
                show-toolbar
                :columns="typeList"
                @cancel="isShow = false"
                :default-index="number"
                value-key="dictionaryTitle"
                @confirm="changeType"
            />
          </van-popup>
        </div>
      </div>
      <!--合同列表区域-->
      <div class="blankWeight"></div>
      <div class="contracts">
        <van-pull-refresh v-model="refreshing"  @refresh="onRefresh">
          <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="noContent ? '' : '已经到底了'"
              @load="this.onLoad"
          >
            <!--合同列表-->
            <div class="contract" v-for="(item,index) in contractList" :key="index">
              <div :class="item.labelText=='已审批'?'top-title-green':(item.labelText=='已提交' || item.labelText=='已签字' || item.labelText=='上级已审批')?'top-title-red':item.labelText=='已取消'?'top-title-gray':'top-title-orange'">{{item.labelText}}</div>
              <div class="position">{{item.houseAddress}}</div>
              <div class="tel-icon" @click="relationTo(item)" v-if="(type=='1'&&checkAuthList(authRenterButtonsList,'findPhone')) ||(type=='2'&&checkAuthList(authOwnerButtonsList,'findPhone'))"></div>
              <div class="details">
                <div class="info">
                  <div class="order">类型</div>
                  <div class="order-name">{{item.typeVal}}</div>
                </div>
                <div class="line"></div>
                <div class="info">
                  <div class="order">解除日期</div>
                  <div class="order-name">{{item.estimatedDepartureTime}}</div>
                </div>
                <div class="line"></div>
                <div class="info">
                  <div class="order">{{item.contractTypeVal}}</div>
                  <div class="order-name">{{item.glouserName}}</div>
                </div>
                <div class="line"></div>
                <div class="info">
                  <div class="order">交割状态</div>
                  <div class="order-name">{{item.tcp}}</div>
                </div>
              </div>
              <div class="contract-info" @click="detailsClick(index,item)" :style="item.labelText!='已取消' && item.labelText!='已审批' && staffType==true?{borderBottom:'1px solid #e0e0e0'}:{}">
                <div class="contract-text">合同编号：{{item.contractCode}}</div>
                <div class="contract-text">签约时间：{{item.signTime}}</div>
                <div class="contract-text">租期：{{item.contractBeginTime}} 至 {{item.contractEndTime}}</div>
                <div class="contract-text" v-if="type=='1'">是否补款：{{item.isUserPayed==1?'是':'否'}}</div>

<!--                <div class="contract-text" v-if="item.fangxinunionId&&item.fangxinunionId!=''&&item.fangxinAuthentication==0">该用户未进行实名认证操作</div>-->
              </div>
              <!--已取消和已审批-->
              <div class="bottom" v-if="item.labelText=='已取消' || item.labelText=='已审批' || item.labelText=='上级已审批'">
              </div>
              <!--申请中、已提交、已签字、上级已审批-->
              <div class="bottom" v-else>
                <div @click="confirm(item)" class="bottom-property" v-if="(type=='1'&&item.maintainStaff_id==staffId||type=='2')&&staffType==true">
                  {{item.contractPropertyId?'修改解除交割单':'添加解除交割单'}}
                </div>
                <!--                v-if="item.matainStaff_id==staffId"-->
                <!--                <div @click="confirm(item)" class="bottom-property"-->
                <!--                     v-if="(item.approvalStatusVal=='已审批'&&isChooseLeaseChange == '1')&&((!item.isUserComfirmProperty || item.isUserComfirmProperty=='0')&&-->
                <!--                                (!item.contracthistoryId || (item.contracthistoryId && item.contractHistoryStatus != '3'&&item.contractHistoryHistoryType!=1)))&&item.matainStaff_id==staffId">-->
                <!--                  {{item.contractPropertyId?'修改解除交割单':'添加解除交割单'}}-->
                <!--                </div>-->
                <div @click="goCancel(item)" class="bottom-property"
                     v-if="cancelLimits&&(item.contractHistoryStatus==0||item.contractHistoryStatus==5||item.contractHistoryStatus==6||item.contractHistoryStatus==7)&&staffType==true">取消</div>
              </div>
            </div>
            <common-empty v-if="noContent"></common-empty>
          </van-list>
        </van-pull-refresh>
        <!--拨打电话弹窗-->
        <dial-mobile :ownerMobile="temporaryOwnerMobile" :userName="temporaryOwnerName"
                     :dialogTitle="dialogTitle" :mobileShow="isMobileShow" @hideDialog="hideDialog"></dial-mobile>
      </div>
    </div>
  </div>
</template>

<script>
import rxNavbar from "../../../components/rongxun/rx-navBar/navBar";
import {Dialog, NavBar, Overlay, Picker, Popup, PullRefresh, Toast, List} from "vant";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import dialMobile from "../../../components/rongxun/rx-dialMobile/dialMobile";
import {checkAuthList, getStaffId, globaluserId, responseUtil} from "../../../libs/rongxunUtil";
import {compulsoryDissolution, findContractHistoryList, getStaffFuntionModelList, userGetPassword} from "../../../getData/getData";
export default {
  name: "contractChange",
  components:{
    [NavBar.name]:NavBar,
    [Toast.name]:Toast,
    [Dialog.name]:Dialog,
    [Picker.name]: Picker,
    [Popup.name]:Popup ,
    rxNavBar,
    dialMobile,
    [PullRefresh.name]:PullRefresh,
    [Overlay .name]: Overlay ,
    [List.name]:List,
  },
  data(){
    return{
      fromType:'',  // 入口标识，0 为房源相关页面
      type:'1',//1代表租客,2代表业主
      contractList:[],
      isChooseLeaseChange:'1',//用户是否点击过租约变更查询租约变更 0代表没有点击，1代表点击了
      page:1,
      loading:false,
      finished:false,
      refreshing:false,
      isShow:false,//显示与影藏下拉条件选择菜单
      number:1,//记录当前选择的是哪一种类型的合同
      noContent:false,
      currentLabel:'',
      searchName:'',
      typeList:[//合同全部类型
        {dictionaryTitle:'全部'}, {dictionaryTitle:'待受理'},
        {dictionaryTitle:'变更中'}, {dictionaryTitle:'已完成'},
        {dictionaryTitle:'已取消'},
      ],
      authOwnerButtonsList:[],//业主合同按钮权限列表
      authRenterButtonsList:[],//租客合同按钮权限列表
      cancelLimits:false,
      isShowdetailInfo:false,
      //拨打电话弹窗
      isMobileShow:false,
      temporaryOwnerMobile:'',
      temporaryOwnerName:'',
      dialogTitle:'联系业主',
      doorPassWord:'',
      districtPassWord:'',
      pwdShow:false,
      staffId:'',  //当前登录人id
      staffType:true,  //默认为合同维护人true传值0
      identityTypeStr:'房源负责人',  //切换目标身份
    }
  },
  created() {
    // 入口标识（fromType 0  房源相关页面进入）
    if(this.$route.query.fromType){
      this.fromType = this.$route.query.fromType
    }
    if(this.$route.query.fromType == '0'){
      this.type = Number(this.$route.query.type)
    }else{
      if(localStorage.getItem('type')){
        let type = JSON.parse(localStorage.getItem('type'))
        if(undefined !== type && '' !== type){
          this.type = Number(type)
        }
      }
      // 合同类型 业主 or 租客
      //debugger
      if(this.$route.query.type){
        //先判断有无缓存,无缓存再set操作
        if(!localStorage.getItem('type')){
          localStorage.setItem('type',JSON.stringify(this.$route.query.type))
          if(this.$route.query.type == '1') {
            this.type == '1'
            this.showTenant()
          }
          if(this.$route.query.type == '2') {
            this.type == '2'
            this.showOwner()

          }
        }
      }
      this.staffType = true
    }
  },
  beforeRouteEnter(to, from, next) {
    //清除本地localStorage缓存
    if(from.name == null){
      localStorage.removeItem('currentLabel');
      localStorage.removeItem('type');
      localStorage.removeItem('inputValue');
      localStorage.removeItem('currentLabelPublic');
    }
    if(from.name == 'contractChangeDetail'){
      to.meta.keepAlive = true;
    }else {
      to.meta.keepAlive = false;
    }
    next()
  },
  mounted() {
    // this.number = 7
    this.getOwnerStaffFuntionModelList()
    this.getRenterStaffFuntionModelList()
    // this.changeType('',this.number)
  },
  methods:{
    //切换租约合同
    showTenant(){
      let that=this
      that.contractList=[]
      that.type='1'
      that.changeType('',that.number)
    },
    //切换业主合同
    showOwner(){
      let that=this
      that.contractList=[]
      that.type='2'
      that.changeType('',that.number)
    },
    //点击合同类型触发的筛选事件
    changeType:function(value,index){
      this.isChooseLeaseChange = '1'
      this.page=1
      this.contractList=[]
      this.loading = false
      this.finished = false
      this.isShow=false
      this.number=index
      //合同状态
      localStorage.setItem('currentLabel',JSON.stringify(this.number+1))
      //合同类型
      localStorage.setItem('type',JSON.stringify(this.type))
      this.loading = true
      this.noContent = false
      this.onLoad()
    },
    // 切换身份
    switchIdentity(){
      this.staffType  = !this.staffType
      this.isChooseLeaseChange = '1'
      this.page=1
      this.contractList=[]
      this.loading = false
      this.finished = false
      this.isShow=false
      //合同状态
      localStorage.setItem('currentLabel',JSON.stringify(this.number))
      //合同类型
      localStorage.setItem('type',JSON.stringify(this.type))
      this.loading = true
      this.noContent = false
      this.onLoad()
    },
    onLoad:function(){
      this.contractListInit()
    },
    checkAuthList,
    contractListInit(){
      let that = this
      let contractListData={}
      contractListData.contractType=that.type=='1'?'0':'1'
      switch (parseInt(that.number)) {
        case 0:
          //全部类型
          that.currentLabel='1'
          break;
        case 1:
          //代受理
          that.currentLabel='2'
          break;
        case 2:
          //变更中
          that.currentLabel='3'
          break;
        case 3:
          //已完成
          that.currentLabel='4'
          break;
        case 4:
          //已取消
          that.currentLabel='5'
          break;
        // case 5:
        //   //待交割
        //   that.currentLabel='6'
        //   break;
        // case 6:
        //   //未交房
        //   that.currentLabel='7'
        //   break;
        // case 7:
        //   //预到期
        //   that.currentLabel='8'
        //   break;
        // case 8:
        //   //驳回
        //   that.currentLabel='9'
        //   break;
      }
      this.staffId = getStaffId()
      contractListData.currentPage=that.page+''
      contractListData.numberPage='3'
      contractListData.contractStatus=''
      contractListData.roleType=''
      contractListData.user_id=getStaffId()
      contractListData.globaluserId=''
      //contractListData.globaluserId=globaluserId()
      contractListData.dicId=''
      contractListData.keywords=''
      contractListData.currentLabel=that.currentLabel
      contractListData.searchName=that.searchName
      // contractListData.isChooseLeaseChange=that.isChooseLeaseChange
      // contractListData.roomHouse_id=that.roomHouse_id  // 房源相关页面进入，根据roomhouse_id查询相关历史租客合同
      // contractListData.setHouse_id=that.setHouse_id  // 房源相关页面进入，根据setHouse_id查询相关历史租客合同
      //that.chooseType(that.currentLabel)
      contractListData.staffType = that.staffType?'0':'1'
      contractListData.mobileFlag=1
      findContractHistoryList(contractListData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          var count = response.data.data.pageCount
          let list = response.data.data.data
          if(count==undefined){  //有错误时防止死循环
            that.finished = true;
          }
          if (list){
            for(let i=0;i<list.length;i++){
              that.contractList.push(list[i])
            }
          }
          that.loading = false;
          if(that.refreshing){
            that.refreshing = false
          }
          if (that.contractList.length >=count) {
            that.finished = true;
          }
          if(1==that.page&&!list){
            that.noContent = true
          }else{
            that.noContent = false
          }
          that.page++;
        })
      })

    },
    //搜索按钮
    searchSubmit(){
      this.isChooseLeaseChange = '1'
      this.changeType('',this.number)
    },
    // 刷新
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.contractList = []
      this.page = 1
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.refreshing = false;
      this.onLoad();
      responseUtil.alertMsg(this,"刷新成功")
    },

    //获取租客合同权限方法
    getRenterStaffFuntionModelList () {
      var that = this
      let data = {}
      data.staff_id = getStaffId()
      data.menuName = 'renterContract_index'
      getStaffFuntionModelList(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.authRenterButtonsList = response.data.data.data
          for(var i in that.authRenterButtonsList){
            if('cancel'==that.authRenterButtonsList[i]){
              that.cancelLimits=true
              break
            }
            if('findPhone'==that.authRenterButtonsList[i]){
              that.isShowdetailInfo=true
              break
            }
          }
        })
      })
    },
    //获取业主合同权限方法
    getOwnerStaffFuntionModelList () {
      var that = this
      let data = {}
      data.staff_id = getStaffId()
      data.menuName = 'ownerContractList_index'
      getStaffFuntionModelList(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.authOwnerButtonsList = response.data.data.data
        })
      })
    },


    //拨打电话
    relationTo(item){
      if(this.type=='2'){
        this.temporaryOwnerMobile = item.mobile
        this.temporaryOwnerName = item.userName
        this.isMobileShow = !this.isMobileShow
        this.dialogTitle = '联系业主'
      } else {
        this.temporaryOwnerMobile = item.mobile
        this.temporaryOwnerName = item.userName
        this.isMobileShow = !this.isMobileShow
        this.dialogTitle = '联系租客'
      }
    },
    //关闭拨打电话弹窗
    hideDialog() {
      let that = this;
      that.isMobileShow = false;
    },
    //获取密码
    getPassword(e){
      var that=this
      var initData={}
      initData.user_id = globaluserId()
      initData.setHouse_id = e.setHouse_id
      initData.roomHouse_id = e.roomHouse_id
      //initData.setHouse_id =10
      //initData.roomHouse_id=283
      initData.type = "6"
      userGetPassword(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.doorPassWord = response.data.data.password
          that.districtPassWord = response.data.data.privatePwd

        })
        that.pwdShow = true
      })
    },
    //确认物业交割
    confirm(item){
      let type = '0'//0代表添加，1代表修改
      // let propertyType = '1'//默认由合同添加物业交割单,如果传入值为2表示由租约变更添加物业交割单
      if(item.contractPropertyId){
        type = '1'
      }
      let propertyType = '2'//默认由合同添加物业交割单,如果传入值为2表示由租约变更添加物业交割单
      this.$router.push({
        name : 'addPropertyBill',
        query : {
          roomHouse_id: item.roomHouse_id,
          setHouse_id: item.setHouse_id,
          contractId: item.contractId,
          contractPropertyId: item.contractPropertyId,
          type:type,
          propertyType:propertyType,
          contracthistoryId:item.contracthistoryId || '',
          mold:this.type,
          houseType:item.houseType,   //房源类型
          flag:"jiechu"
        }
      })
    },
    //取消
    goCancel(item){
          Dialog.confirm({
            title: '友情提示',
            message: '确定取消租约变更吗？',
          })
          .then(() => {
            // on confirm
            let that = this
            let queryData = {};
            queryData.contractHistoryId = item.contracthistoryId;
            queryData.status = '3';
            queryData.user_id = getStaffId()//globaluserId();
            queryData.contractHistoryHistoryType = item.contractHistoryHistoryType
            queryData.contractType = item.contractType==='0'?'':'1'
            compulsoryDissolution(queryData).then(function (response) {
              responseUtil.dealResponse(that, response, () => {
                that.finished = false;
                that.contractList = []
                that.page = 1
                that.loading = true;
                that.refreshing = false;
                that.onLoad()
              })
            })
          })
          .catch(() => {
            // on cancel
          })
    },
    //合同详情
    detailsClick(index,item){
      if((this.type=='1'&&checkAuthList(this.authRenterButtonsList,'showDetail')) ||(this.type=='2'&&checkAuthList(this.authOwnerButtonsList,'showDetail'))){
        let ownerOrRenterContractId
        // if(this.type=='1'){
        //   ownerOrRenterContractId = this.contractList[index].renterContractId
        // } else {
        //   ownerOrRenterContractId = this.contractList[index].ownerContractId
        // }
        if(this.contractList[index].contractType=='1'){  //业主
          ownerOrRenterContractId = this.contractList[index].ownerContractId
        }else{
          ownerOrRenterContractId = this.contractList[index].renterContractId
        }
        this.$router.push({
          name : 'contractChangeDetail',
          query : {
            contractId:this.contractList[index].contractId,
            contractHistoryId:this.contractList[index].contracthistoryId ||'',
            type:this.type,
            ownerOrRenterContractId:ownerOrRenterContractId,
            historyType:this.contractList[index].typeVal,
            contractTypeVal:this.contractList[index].contractStatusVal,
            contractStatus:this.contractList[index].contractStatus,
            mode:'1',
            isShowdetailInfo:this.isShowdetailInfo,
            houseType:this.contractList[index].houseType,
            typeVal:item.typeVal,
            labelText:item.labelText,
            houseAddress:item.houseAddress,
            shhouseCode:item.shhouseCode,
            contractCode:item.contractCode,
            glouserName:item.glouserName,
            houseEndTime:item.houseEndTime,
            rentuserBankName:item.rentuserBankName,
            rentuserBank:item.rentuserBank,
            rentuserBankCode:item.rentuserBankCode,
            rentuserSubBank:item.rentuserSubBank,
            province:item.province,
            city:item.city,
            isNormalReturn:item.isNormalReturn,
            estimatedDepartureTime:item.estimatedDepartureTime,
            defaultingParty:item.defaultingParty,
            defaultingType:item.defaultingType,
            terminationType:item.terminationType,
            terminationReason:item.terminationReason,
            billFlag:item.billFlag,
            contractTerminationTypeStr:item.contractTerminationTypeStr,
            contractTerminationReasonStr:item.contractTerminationReasonStr,
            actualDepartureTime:item.actualDepartureTime,
            overdueDays:item.overdueDays,
            restRentMoney:item.restRentMoney,
            restWaterElectric:item.restWaterElectric,
            falsify:item.falsify,
            afterrentCompensation:item.afterrentCompensation,
            payRepair:item.payRepair,
            reserveWaterElectric:item.reserveWaterElectric,
            totalRefunded:item.totalRefunded,
            isUserPayed:item.isUserPayed,
            UserPayedMoney:item.UserPayedMoney,
            realFalsify:item.realFalsify,
            lateFee:item.lateFee,
            interest:item.interest,
            surplusRent:item.surplusRent,
            restWater:item.restWater,
            serviceCharge:item.serviceCharge,
            surplusgoodsPrice:item.surplusgoodsPrice,
            waterPowerPayFor:item.waterPowerPayFor,
            userTotalPay:item.userTotalPay,
            realBreakMoney:item.realBreakMoney,
            decorationDepreciation:item.decorationDepreciation,
            remarks:item.remarks,
            chAddTime:item.chAddTime,
            addStaffName:item.addStaffName,
            maintainStaffName:item.maintainStaffName,
            renterContractId:item.renterContractId,
            newrenterContractId:item.newrenterContractId,
            newownerContractId:item.newownerContractId,
            newlContract_id:item.newlContract_id,
            contracthistoryId:item.contracthistoryId,
          }
        })
      }
    },
  }
}
</script>

<style scoped>
  .outside{
    overflow: hidden;
    width: 100%;
  }
  .header{
    width: 100%;
    height: auto;
    position: fixed;
    z-index: 8;
    top: 50px;
    background-color: #f8f8f8;
  }
  /*tab页*/
  .choose{
    width: 100%;
    height: 56px;
    background-color: white;
    display: flex;
  }
  .choose-one{
    width: 187px;
    text-align: center;
  }
  .choose-one-textActive{
    font-size: 14px;
    color: #fe5e3a;
    font-weight: bold;
    margin-top: 10px;
  }
  .choose-one-text{
    font-size: 14px;
    color: #999999;
    margin-top: 10px;
  }
  .choose-one-icon{
    width: 20px;
    height: 4px;
    border-radius: 3px;
    background-color: #fe5e3a;
    margin: 10px 0 0 84px;
  }
  .choose-line{
    width: 1px;
    height: 17px;
    margin-top: 13px;
    background-color: #e0e0e0;
  }
  /*搜索框*/
  .search{
    margin: 15px 20px 0 20px;
    height: 35px;
    border-radius: 30px;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
  }
  .search-icon{
    width: 25px;
    height: 25px;
    margin-left: 10px;
    background-image: url("../../../assets/images/search-icon.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
  input{
    font-size: 14px;
    margin-left: 15px;
    margin-top: 3px;
    background-color: #f4f4f4;
    border: 0;
    width: 250px;
  }
  input::-webkit-input-placeholder{
    color: #d9d9d9;
  }
  /*搜索框下面的合同状态和切换身份*/
  .typeAndChange{
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 15px;
  }
  /*切换合同类型*/
  .types{
    height: 45px;
    display: flex;
    align-items: center;
  }
  .type{
    font-size: 14px;
    color: #fe5e3a;
  }
  .small-icon{
    width: 8px;
    height: 8px;
    margin-left: 5px;
    background-image: url("../../../assets/images/small-icon.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .small-icon-rotate{
    width: 8px;
    height: 8px;
    margin-left: 5px;
    margin-bottom: 5px;
    background-image: url("../../../assets/images/small-icon.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    transform:rotate(180deg);
  }
  /*切换身份*/
  .switchIdentity{
    font-size: 14px;
    color: #fe5e3a;
  }

  /*分割*/
  .blankWeight{
    height: 151px;
  }
  /*合同信息*/
  .contracts{
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .contract{
    position: relative;
    margin: 0 15px 15px 15px;
    height: auto;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
  }
  /*左上角的状态颜色*/
  .top-title-orange{
    margin: 0;
    width: 65px;
    height: 20px;
    font-size: 10px;
    text-align: center;
    line-height: 22px;
    border-radius: 8px 0;
    background: linear-gradient(to right,#ffbe72,#ff6c41);
    color: white;
  }
  .top-title-green{
    margin: 0;
    width: 65px;
    height: 20px;
    font-size: 10px;
    text-align: center;
    line-height: 22px;
    border-radius: 8px 0;
    background: linear-gradient(to right,#63cb96,#30d0ac);
    color: white;
  }
  .top-title-red{
    margin: 0;
    width: 65px;
    height: 20px;
    font-size: 10px;
    text-align: center;
    line-height: 22px;
    border-radius: 8px 0;
    background: linear-gradient(to right,#fc401b,#e02321);
    color: white;
  }
  .top-title-gray{
    margin: 0;
    width: 65px;
    height: 20px;
    font-size: 10px;
    text-align: center;
    line-height: 22px;
    border-radius: 8px 0;
    background: grey;
    color: white;
  }
  /*房源地址*/
  .position{
    margin: 15px 50px 0 15px;
    height: auto;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  /*电话*/
  .tel-icon{
    width: 25px;
    height: 25px;
    position: absolute;
    top: 32px;
    right: 20px;
    background-image: url("../../../assets/images/relation-phone.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
  /*四分格详情*/
  .details{
    margin-top: 15px;
    width: 100%;
    height: auto;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
  }
  .details .info{
    width: 85.5px;
    height: auto;
    text-align: center;
  }
  .details .info .order{
    width: 100%;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #999999;
  }
  .details .info .order-name{
    width: 100%;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #fe5e3a;
    margin-top: 10px;
    margin-bottom: 15px;
    white-space: nowrap;
    text-overflow:ellipsis;
    overflow: hidden;
    font-weight: bold;
  }
  .details .line{
    width: 1px;
    height: 40px;
    background-color: #e0e0e0;
    margin-top: 8px;
  }
  /*整块*/
  .contract-info{
    width: 100%;
    height: auto;
    padding: 15px 0 10px 0;
    /*border-bottom: 1px solid #e0e0e0;*/
  }
  .contract-text{
    height: 25px;
    line-height: 25px;
    text-align: left;
    margin-left: 20px;
    color: #999999;
    font-size: 14px;
  }
  .bottom-property{
    float: right;
    width: 90px;
    height: 30px;
    /*margin-top: 15px;*/
    /*margin-bottom: 15px;*/
    /*margin-left: 11px;*/
    /*margin-right: 11px;*/
    margin: 15px 15px 15px 0px;
    right: 15px;
    line-height: 30px;
    background: linear-gradient(to right,#ffbe72,#ff6c41);
    color: white;
    font-size: 12px;
    text-align: center;
    border-radius: 8px;
  }
  .bottom-changeLease{
    float: right;
    width: 80px;
    height: 30px;
    margin-top: 15px;
    margin-left: 11px;
    line-height: 30px;
    background: linear-gradient(to right,#7cc7ff,#3596fd);
    color: white;
    font-size: 12px;
    text-align: center;
    border-radius: 8px;
  }
</style>